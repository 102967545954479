<template>
	<v-app>
		<div class="d-flex-column justify-center align-center">
			<!-- Header Segment -->
			<div>
				<common-home-header @userLoggedIn="checkIfUserLoggedOut" :callpermissionflag="callpermissionflag" :errorDialogue = "error_enable" :authDialogue = "auth_error_enable"></common-home-header>
			</div>

			<v-divider></v-divider>

			<!-- Navigation Bar Segment -->
			<div class="navigation-tabs-segment">
				<header-tabs-component :tab_id="1" :is_user_logged_in="isUserLoggedIn"></header-tabs-component>
			</div>

			<div class="display-web">
				<v-divider></v-divider>
			</div>

			<!-- Search Segment -->
			<!-- <div class="default-padding display-mobile search-container">
				<div class="search-segment">
					<v-text-field solo flat placeholder="Search" hide-details="auto" background-color="#F4F6FA" class="ma-0 pa-0 search-bar"
						@click="" @keyup.enter="" @click:append=""
					>
						<template #append>
							<img class="ma-0 pa-0 search-icon-class" src="https://s3iconimages.mymedicine.com.mm/search_common_home_icon.svg" alt="Search Icon">
						</template>
					</v-text-field>
				</div>
			</div> -->

			<div class="container">
				<!-- Loader Web -->
				<div v-if="skeletonLoaderFlag" class="loader-web">
					<!-- Banner Segment -->
					<div class="banners-segment">
						<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
					</div>

					<!-- Dynamic Segments -->
					<div class="products-segment">
						<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

						<div class="d-flex">
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
						</div>

						<div class="d-flex">
							<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
							<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
						</div>
					</div>

					<div class="pa-4">
						<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

						<div class="d-flex">
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
						</div>

						<div class="d-flex flex-column">
							<div class="py-2 d-flex">
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
							</div>
							
							<div class="py-2 d-flex">
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
							</div>
						</div>
					</div>

					<div class="pb-5 banners-segment">
						<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
					</div>
				</div>
				<!-- Loader Mobile -->
				<div v-if="skeletonLoaderFlag" class="loader-mobile">
					<!-- Banner Segment -->
					<div class="banners-segment">
						<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
					</div>
					
					<!-- Dynamic Segments -->
					<div class="px-4 products-segment">
						<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

						<div class="d-flex">
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
						</div>

						<div class="d-flex">
							<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
							<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
						</div>
					</div>

					<div class="pa-4">
						<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

						<div class="d-flex">
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
						</div>

						<div class="d-flex flex-column">
							<div class="py-2 d-flex">
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
							</div>
							
							<div class="py-2 d-flex">
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
							</div>
						</div>
					</div>

					<div class="pb-5 banners-segment">
						<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
					</div>
				</div>

				<div v-if="!skeletonLoaderFlag">
					<!-- Banner Segment -->
					<div class="banners-segment" v-if="banners.length > 0">
						<banner :key="bannersComponentKey" :banners="banners" banner_type="BANNERS-ONE-PER-SLIDE"></banner>
					</div>

					<!-- Categories Segment -->
					<div class="specialties-segment" v-if="categories">
						<div v-if="categories.length > 0">
							<explore-categories-component @handleCategorySizeChange="handleCategorySizeChange" :key="categoryComponentKey" :categories="categories" category_type="ePharmacy" :header_data="exploreCategoryHeader" language="en" pageName=""></explore-categories-component>
						</div>
					</div>

					<!-- Dynamic Segments -->
					<div v-for="segment, index in segmentComponents" :key="index">
						<div class="pb-5 banners-segment" v-if="segment.type === 'BANNERS-ONE-PER-SLIDE' || segment.type === 'BANNERS-THREE-PER-SLIDE'">
							<div>
								<banner :key="bannersComponentKey" :banners="segment.items" :banner_type="segment.type"></banner>
							</div>
						</div>

						<div class="products-segment" v-if="segment.type === 'LISTINGS-ONE-ROW'">
							<div>
								<listings-component
									:data="segment"
									listings_type="LISTINGS-ONE-ROW"
									class="rounded-lg"
								></listings-component>
							</div>
						</div>

						<div class="products-segment" v-if="segment.type === 'LISTINGS-TWO-ROWS'">
							
						</div>

						<div class="products-segment" v-if="segment.type === 'ARTICLES'">
							<listings-component
								:data="segment"
								listings_type="ARTICLES"
							></listings-component>
						</div>
					</div>
				</div>
			</div>
			
			<!-- Bottom Navigation Bar (Mobile) -->
			<div class="bottom-navbar-segment mobile-bottom-nav-bar">
				<bottom-nav-bar-component  :selectedOption="1" />
				<!-- <bottom-navigation-bar highlight_icon="pharmacy"></bottom-navigation-bar> -->
			</div>

			<!-- Footer -->
			<div class="display-web">
				<v-divider></v-divider>
			</div>
			
			<div class="pa-4 footer-segment">
				<common-home-footer></common-home-footer>
			</div>
		</div>
	</v-app>
</template>

<script>
import axios from 'axios';
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
import BottomNavBarComponent from '../../../components/nurseBookingPageComponents/bottomNavBarComponent.vue';

export default {
	name: 'ePharmacyHomePage',
	components: {
		'common-home-header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
		'header-tabs-component': () => import('../../../components/navigationComponents/headerTabsComponent.vue'),
		'banner': () => import('../../../components/ePharmacyComponents/bannersHomeComponent.vue'),
		'explore-categories-component': () => import('../../../components/ePharmacyComponents/categoryHomePageComponent.vue'),
		'listings-component': () => import('../../../components/ePharmacyComponents/multipleStyleListingsComponent.vue'),
		'bottom-navigation-bar': () => import('../../../components/navigationComponents/bottomNavigationBar.vue'),
		'common-home-footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue'),
		BottomNavBarComponent
	},
	data() {
		return {
			screenWidth: window.innerWidth,
			skeletonLoaderFlag: true,
			isUserLoggedIn: false,
			banners: [],
			bannersComponentKey: 0,
			segmentComponents: [],
			productsList: [],
			exploreCategoryHeader: {},
			categories: [],
			categoryComponentKey: 0,
			callpermissionflag: false,
			auth_error_enable: false,
			error_enable: false,
			token: null
		}
	},
	watch: {
		screenWidth(newWidth, oldWidth) {
		},
	},
	mounted() {
		if (window.xm){
      		document.title = '';
    	} else {
			document.title = 'ePharmacy';
		}
		if (this.$store.state.locale=='') {
            this.$i18n.locale = 'mm';
            this.$store.dispatch("changeLocale", this.$i18n.locale);
        }
        else
            this.$i18n.locale = this.$store.state.locale;
		this.language = this.$i18n.locale;

		window.addEventListener('resize', this.handleResize);

		if (this.$cookies.get('customerToken')===null)
			this.displayLoginSignupButton = true;
		else {
			this.displayLoginSignupButton = false;
			this.token = this.$cookies.get('customerToken');
		}

		// Fetch data from pagewise API.
		axios_auth_instance_epharmacy_customer.get('/pagewise/epharmacyHomePage/').then((epharmacyHomePageResponse) => {
			// Check authentication status.
			this.isUserLoggedIn = epharmacyHomePageResponse.data.authenticationStatus == 401 ? false : true;
            if (epharmacyHomePageResponse.data.customerBasicData) {
				if (epharmacyHomePageResponse.data.customerBasicData.language == 'en') {
					this.$i18n.locale = 'en';
				} else {
					this.$i18n.locale = 'mm';
				}
	            this.$store.dispatch("changeLocale", this.$i18n.locale);
            	this.$store.dispatch("changeName", epharmacyHomePageResponse.data.customerBasicData.name);
			}
			this.display_data = epharmacyHomePageResponse.data.data.display_data;

			// Banners Segment.
			this.banners = this.display_data.banners;

			// Categories Segment.
			this.exploreCategoryHeader = {
				icon: 'https://s3iconimages.mymedicine.com.mm/explore_by_specialities_home_icon.svg',
				heading_name: 'Explore By Categories',
				pagename: 'AllCategoriesPage'
			};
			if (this.display_data.categories) {
				this.categories = this.display_data.categories;
			}

			// Slider Segments.(Product)
			this.segmentComponents = this.display_data.segments;

			this.skeletonLoaderFlag = false;
			this.callpermissionflag = true;
		}).catch((epharmacyHomePageError) => {
			if (epharmacyHomePageError.status === 401) {
				this.$cookies.remove('customerToken');
				this.$router.go();
				//epharmacy Home Page
			} else {
				console.log('Error fetching ePharmacy home page data: ', epharmacyHomePageError);
				this.error_enable = true;
			}
		});
		this.userPageViewTable();
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName: 'ePharmacyHomePage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'ePharmacyHomePage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
		checkIfUserLoggedOut(data) {
			this.isUserLoggedIn = data;
		},
		handleResize() {
			this.skeletonLoaderFlag = true;

			this.screenWidth = window.innerWidth;
			this.bannersComponentKey++;
			this.categoryComponentKey++;

			this.skeletonLoaderFlag = false;
		},
		handleCategorySizeChange() {
			this.skeletonLoaderFlag = true;

			this.categoryComponentKey++;

			this.skeletonLoaderFlag = false;
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';

$mobile-heading-font-size: 14px;
$desktop-heading-font-size: 20px;

.loader-web, .navigation-tabs-segment, .footer-segment, .display-web {
	@media (max-width: 600px) {
        display: none !important;
	}
}

.loader-mobile, .display-mobile {
	@media (max-width: 600px) {

	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none;
	}

	@media (min-width: 1265px) {
		display: none;
	}
}

.banners-segment {
	width: 100%;
	height: auto;

	@media (max-width: 600px) {
        padding-top: $content-padding-mobile;
		padding-bottom: calc( $content-padding-mobile / 2);
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: calc( $content-padding-medium / 2 );
		padding-left: $margin-header-x-medium;
		padding-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: calc( $content-padding-web / 2 );
		padding-left: $margin-header-x-web;
		padding-right: $margin-header-x-web;
	}
}

.specialties-segment {
	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		margin-left: $margin-header-x-mobile;
		margin-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: $content-padding-medium;
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		border-radius: 8px;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: $content-padding-web;
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		border-radius: 8px;
	}
}

.promo-banner-segment {
	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: $content-padding-medium;
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: $content-padding-web;
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;
	}
}

.text-heading {
	font-weight: 600;
	text-align: left;

	@media (max-width: 600px) {
		font-size: $mobile-heading-font-size;
		line-height: 20px;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		font-size: $desktop-heading-font-size;
		line-height: 30px;
	}

	@media (min-width: 1265px) {
		font-size: $desktop-heading-font-size;
		line-height: 30px;
	}
}
.feature-tabs-segment {
	background-color: #F4F6FA;
	border-radius: 8px;

	@media (max-width: 600px) {
        padding: 16px;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding: 16px 32px 16px 32px;
	}

	@media (min-width: 1265px) {
		padding: 16px 128px 16px 128px;
	}
}
.feature-tabs-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.feature-tab {
	display: flex;
	flex-direction: column;
	border-radius: 4px;

	@media (max-width: 600px) {
		padding: 16px;
		justify-content: space-between;
		align-items: center;
	}

	@media (min-width: 601px){
		padding: 24px;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.feature-text {
	color: white;

	@media (max-width: 600px) {
		text-align: center;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		text-align: left;
	}

	@media (min-width: 1265px) {
		text-align: left;
	}
}
.teleconsultation-tab {
	width: 49%;
	background-color: #F89035;
}
.ePharmacy-tab {
	width: 49%;
	background-color: #1467BF;
}
.banner-image {
	width: 100%;
    height: 100%;
    object-fit: fill;
}

.search-container, .search-segment {
	width: 100%;
}

.products-segment {
	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: calc($content-padding-medium / 2);
		padding-bottom: calc($content-padding-medium / 2);
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: calc( $content-padding-web / 2);
		padding-bottom: calc( $content-padding-web / 2);
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;
	}
}
.mobile-bottom-nav-bar {
	@media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none !important;
	}

	@media (min-width: 1265px) {
		display: none !important;
	}
}
</style>

<style lang="scss">
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';

</style>